import type { Catalog, Search } from '#root/api/clients/product/data-contracts'

/**
 * Transforms the filters from a catalog or search response so they can be safely used in the query string.
 * @param filters The filters object to be transformed.
 * @returns Transformed filters object.
 * @category Utils
 */
export default (filters: Catalog['filters'] | Search['filters']): typeof filters => filters?.map((filter) => ({
  ...filter,
  options: filter.options?.map((option) => ({
    ...option,
    id: option.id?.replace(';', '%3B')
  }))
}))
