const getPriceRange = (value: string | string[]) => {
  if (Array.isArray(value) && value.length === 2) {
    const min = Number.parseInt(value[0])
    const max = Number.parseInt(value[1])
    if (!Number.isNaN(min) && !Number.isNaN(max)) return { min, max }
  }
  return value
}

/**
 * Transforms filters into a string suitable for URL parameters.
 * @param filters The filters object to be transformed.
 * @returns Transformed filters string.
 * @category Utils
 */
export default (filters: string) => filters
  ? JSON.stringify(Object.fromEntries(
    Object.entries(JSON.parse(filters) as [string, string[]])
      .map(([code, selected]) => [code, code.toLocaleLowerCase() === 'lowcurrent'
        ? getPriceRange(selected)
        : selected])
  ))
  : ''
